<template>
  <div class="campaigns">
    <div class="campaign-list">
      <div class="container">
        <div class="header-wrap cf">
          <div class="header-left cf">
            <img src="../../assets/icons/media.svg" />
            <h2>{{ $t("ads.title") }}</h2>
            <h3>{{ $t("ads.description") }}</h3>
          </div>
          <div class="header-right">
            <p>
              <router-link to="/advertisments/new" class="button button-ci button-round button-small">
                <img src="../../assets/icons/plus.svg" />
                <span>{{ $t("ads.add") }}</span>
              </router-link>
            </p>
          </div>
        </div>

        <div v-if="loading">
          <div class="loading-wrap">
            <div class="loading-spinner"></div>
          </div>
        </div>

        <div v-else>
          <div v-if="advertisments.length == 0">
            <div class="well">
              <h3>{{ $t("ads.empty") }}</h3>
              <p>
                <router-link to="/advertisments/new" class="button button-ci button-round button-small">
                  <img src="../../assets/icons/plus.svg" />
                  <span>{{ $t("ads.button") }}</span>
                </router-link>
              </p>
            </div>
          </div>

          <div v-else>
            <div class="row row-gutter-20">
              <div v-for="advertisment in advertisments" v-bind:key="advertisment.uuid" class="col-6">
                <div class="campaign-wrap">
                  <div class="campaign-img" style="position: relative">
                    <span
                      v-if="advertisment.orientation == 'horizontal'"
                      class="material-icons"
                      style="position: absolute; left: 10px; top: 10px; background: rgba(255, 255, 255, 0.75); border-radius: 3px; padding: 2px 6px"
                      >panorama_horizontal</span
                    >
                    <span
                      v-if="advertisment.orientation == 'vertical'"
                      class="material-icons"
                      style="position: absolute; left: 10px; top: 10px; background: rgba(255, 255, 255, 0.75); border-radius: 3px; padding: 2px 6px"
                      >panorama_vertical</span
                    >
                    <div v-if="advertisment.thumbnail">
                      <span v-if="advertisment.status == 'creating'">
                        <a :href="advertisment.asset_file" target="_blank" style="display: block"><img :src="advertisment.thumbnail" /></a>
                      </span>
                      <span v-else>
                        <a v-if="advertisment.orientation == 'vertical'" :href="advertisment.asset_file" target="_blank" style="display: block"
                          ><img :src="advertisment.thumbnail"
                        /></a>
                        <a v-else :href="advertisment.asset_file" target="_blank" style="display: block"><img :src="advertisment.thumbnail" /></a>
                      </span>
                    </div>
                    <div v-else>
                      <img :src="require('../../assets/themes/' + _self.$theme + '/placeholder.gif')" />
                    </div>
                  </div>
                  <div class="campaign-title">
                    <h3>
                      
                        <span>
                          <small>{{ advertisment.name }}</small>
                            <p>
                              <span v-if="advertisment.status == 'creating'" class="ad-status status-yellow"></span>
                              <span v-if="advertisment.status == 'available'" class="ad-status status-green"></span>
                              <span  v-if="advertisment.advertisment_type == 'image'"> Bild ({{ $t("global.duration") }}: {{ advertisment.duration }}s)
                                <router-link :to="'/advertisments/' + advertisment.uuid" class="material-icons" style="color: #444">edit</router-link>
                              </span>
                              <span  v-if="advertisment.advertisment_type == 'video'"> Video
                                <router-link :to="'/advertisments/' + advertisment.uuid" class="material-icons" style="color: #444">edit</router-link>
                              </span>
                            </p>
                        </span>
                      <span @click="delete_ad(advertisment.uuid)" class="material-icons">delete</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "campaigns",
  data() {
    return {
      errors: [],
      loading: true,
      advertisments: [],
    };
  },
  methods: {
    get_data() {
      this.get_ads(1);
    },
    get_ads(page) {
      axios
        .get(process.env.VUE_APP_BASE_API + "/advertisments?page=" + page, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          if (page == 1) {
            this.advertisments = response.data.advertisments;
          } else {
            response.data.advertisments.forEach(ad => {
              this.advertisments.push(ad);
            });
          }
          if (response.data.meta.pagination.links.next) {
            this.get_ads(response.data.meta.pagination.links.next.split("=")[1]);
          } else {
            this.loading = false;
          }
        });
    },
    delete_ad(ad_uuid) {
      if (confirm("Wirklich löschen?")) {
        axios
        .delete(process.env.VUE_APP_BASE_API + "/advertisments/" + ad_uuid, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.deletedSuccess"),
          });
          this.get_ads(1);
        });
      }
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss">
.campaign-list {
  padding: 40px 0;
}

.campaign-list .campaign-wrap {
  background: #fff;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  padding: 0px;
  border-radius: 5px;
  display: block;
  color: #444;
  margin-bottom: 20px;
}

.campaign-list .campaign-wrap .campaign-img {
  width: 100%;
}

.campaign-list .campaign-wrap .campaign-img img {
  width: 100%;
  height: 160px;
  object-fit: contain;
  background: lightgray;
}

.campaign-list .campaign-wrap .campaign-img a {
  padding: 0;
}

.campaign-list .campaign-wrap .campaign-title {
  padding: 10px 14px;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
  position: relative;

  .material-icons {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
}

.campaign-list .campaign-wrap .campaign-title h3 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.campaign-list .campaign-wrap .campaign-title h3 .badge {
  font-size: 12px;
  margin-right: 10px;
  font-weight: 400;
  background-color: rgba(24, 144, 255, 0.1);
  color: #1890ff;
  border: 1px solid rgba(24, 144, 255, 0.2);
  padding: 4px 8px;
  border-radius: 3px;
}

.campaign-list .campaign-wrap .campaign-title h3 span {
  display: inline-block;
  vertical-align: middle;
}

.campaign-list .campaign-wrap .campaign-meta {
  padding: 10px 14px;
  text-align: center;
}

.campaign-list .campaign-wrap .campaign-meta .col-8 span {
  display: inline-block;
  vertical-align: middle;
}

.campaign-list .campaign-wrap .campaign-meta .col-8 img {
  display: inline-block;
  vertical-align: middle;
  height: 15px;
  margin-right: 5px;
}

.ad-status {
  display: inline-block;
  background: #f1f1f1;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

.ad-status.status-green {
  background: green;
}

.ad-status.status-yellow {
  background: yellow;
}
</style>
